
import { getArticles } from '@/apis/articles';
import { ArticleModel } from '@/model/articleModel';

import { defineComponent, reactive, toRefs, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const dataMap = reactive({
      total: 0,
      list: Array<ArticleModel>(),
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20
      },
      async getList() {
        dataMap.listLoading = true;
        const data = await getArticles(dataMap.listQuery);
        dataMap.list = data?.data.items ?? [];
        dataMap.total = data?.data.total ?? 0;
        // Just to simulate the time of the request
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.5 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList();
    });
    return { ...toRefs(dataMap) };
  }
});
// }
